import makeStyles from "@mui/styles/makeStyles";
import DataGridView from "components/grid/DataGridView";
import {
  tooltipFormatter,
  valueFormatter
} from "components/grid/dateFormatter";
import { RenderableColumnDefinition } from "components/grid/gridTypings";
import { IColumnProps } from "devextreme-react/data-grid";
import {
  InputDataTables,
  QueryCalculationResultInputDataVariables,
  QueryCalculationResultOutputDataVariables,
  QueryInputDataTables,
  QueryInputDataVariables,
  ResultDataTables
} from "features/use-case";

import { Tooltip } from "@mui/material";

import CustomStore from "devextreme/data/custom_store";
import { useSolutionKey } from "features/solution";
import _ from "lodash";

const useStyles = makeStyles({
  flex: { flex: "1 1 auto", overflow: "auto" },
  tooltip: {
    backgroundColor: "#fff",
    border: "solid 1px rgb(0, 0, 0, 0.9)",
    borderRadius: 0,
    color: "rgb(0, 0, 0, 0.9)"
  }
});

type TableSetGridProps<T> = {
  useCaseKey: string;
  useCaseVersion?: string | null;
  dataKey?: string;
  calculationId: string;
  name: string;
  columnDefs?: (
    schemaName: string
  ) => RenderableColumnDefinition<IColumnProps, {}>[];
  loadData: (
    variables: T
  ) => Promise<
    InputDataTables | ResultDataTables | QueryInputDataTables | null | undefined
  >;
};

const TableSetGrid = <
  T extends
    | QueryInputDataVariables
    | QueryCalculationResultInputDataVariables
    | QueryCalculationResultOutputDataVariables
>({
  useCaseKey,
  useCaseVersion = null,
  dataKey,
  calculationId,
  name,
  columnDefs,
  loadData
}: TableSetGridProps<T>) => {
  const solutionKey = useSolutionKey();
  const { flex, tooltip } = useStyles({});

  const dataSetViewModelToObjectRows = (
    table: InputDataTables | ResultDataTables | QueryInputDataTables | undefined
  ) => {
    if (table) {
      const columnNames = columnDefs?.(name)
        .map(({ colDef: { dataField } }) => dataField)
        .filter((columnName): columnName is string => columnName !== undefined);
      const rowObjects = _(table.rows)
        .map(row => row && _.zipObjectDeep(columnNames, row))
        .value();
      return rowObjects;
    }
  };

  const load = async (loadOptions: any) => {
    const skip = loadOptions.skip;
    const take = loadOptions.take;
    const table = dataKey
      ? await loadData({
          solutionKey,
          useCaseKey,
          useCaseVersion,
          keys: [dataKey],
          names: [name],
          skip,
          take
        } as T)
      : await loadData({
          solutionKey,
          useCaseKey,
          useCaseVersion,
          calculationId,
          names: [name],
          skip,
          take
        } as T);
    if (table) {
      const rows = dataSetViewModelToObjectRows(table);
      return {
        data: rows,
        totalCount: table.rowCount
      };
    }
  };

  const RenderableColumns = columnDefs?.(name).map(col => {
    if (col.typeName === "dateTime") {
      return _.omit(
        {
          ...col,
          render: (params: any) => {
            return (
              <Tooltip
                title={tooltipFormatter(params.value)}
                classes={{ tooltip }}
              >
                <div>{valueFormatter(params.value)}</div>
              </Tooltip>
            );
          }
        },
        "typeName"
      );
    } else return _.omit(col, "typeName");
  });

  return (
    <div className={flex}>
      <DataGridView
        dataSource={new CustomStore({ load })}
        columns={RenderableColumns}
        allowSelection={false}
        showFilterRow={false}
      />
    </div>
  );
};

export default TableSetGrid;
