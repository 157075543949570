import { Publish } from "@mui/icons-material";
import DataIcon from "@mui/icons-material/Storage";
import { Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSolutionKey } from "features/solution";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import Topic from "../Topic";
import { useQueryUseCaseDataCount } from "../calculate/calculationHooks";
import DataAndCalculationsCountError from "./DataAndCalculationsCountError";

const useStyles = makeStyles(theme => ({
  right: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "capitalize",
    padding: theme.spacing(1),
    flexDirection: "column"
  },
  text: {
    textAlign: "left",
    color: "#fff",
    width: "100%",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  iconWithBackground: {
    fontSize: 30,
    backgroundColor: "#fff",
    color: "rgba(0,153,203,0.7)",
    borderRadius: 2
  },
  topicColor: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(4)
  },
  icon: { marginRight: 16, fontSize: 60 },
  uploadButton: { position: "absolute", top: 0, right: 0, padding: 24 },
  number: { fontSize: 60, marginRight: 8 },
  dialogTitle: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  }
}));

type DataCardProps = {
  useCaseKey: string;
  useCaseVersion: string;
};

const DataCard: React.FC<DataCardProps> = ({ useCaseKey, useCaseVersion }) => {
  const solutionKey = useSolutionKey();
  const classNames = useStyles();

  const {
    data: dataCount,
    loading,
    error: dataCountError
  } = useQueryUseCaseDataCount({
    solutionKey,
    useCaseKey
  });

  const history = useHistory();

  const count = dataCount?.solutions.solution?.useCases?.useCase?.dataCount;
  const noData = (!count || count === 0) && !dataCountError;

  const toolTipTitle = noData ? "Be the first to upload new data" : undefined;

  let countDisplay;

  if (dataCountError) {
    countDisplay = (
      <DataAndCalculationsCountError
        error={dataCountError}
        tooltipTitle="An error occurred while loading the data, click this icon to see it."
        errorBoxTitle="An error occurred while loading the use case data count!"
      />
    );
  } else if (count !== undefined) {
    countDisplay = (
      <Typography className={classNames.number}>
        {`${count}${count === 999 ? "+" : ""}`}
      </Typography>
    );
  } else {
    countDisplay = null;
  }

  return (
    <Topic
      title="Data"
      icon={<DataIcon fontSize="large" className={classNames.icon} />}
      count={countDisplay}
      isLoading={loading}
      dataTest="topic-upload-data"
      toolTipTitle={toolTipTitle}
      to={
        noData
          ? `/solutions/${solutionKey}/use-cases/${useCaseKey}/${useCaseVersion}/upload-data`
          : "./data"
      }
      colorClassName={classNames.topicColor}
    >
      {!noData ? (
        <Tooltip title="Upload new data">
          <div className={classNames.uploadButton}>
            <Publish
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                history.push(
                  createRoute(
                    `/solutions/${solutionKey}/use-cases/${useCaseKey}/${useCaseVersion}/upload-data`
                  )
                );
              }}
            />
          </div>
        </Tooltip>
      ) : null}
    </Topic>
  );
};

export default DataCard;
