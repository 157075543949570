import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DateCellRenderer } from "components/grid/CellRenderers";
import ConfirmGridActionDialog from "components/grid/ConfirmGridActionDialog";
import DataGridView from "components/grid/DataGridView";
import { UserName } from "features/solution-member";
import React from "react";
import { DeepOmit } from "utils/typescript";
import SessionActions from "./SessionActions";
import { StreamingSessions_solutions_solution_projects_project_streamingSessions } from "./schema/StreamingSessions";

const useStyles = makeStyles(theme => ({
  sessions: {
    display: "flex",
    flexDirection: "column",
    gridArea: "sessions"
  },
  icon: {
    margin: theme.spacing(2)
  }
}));

type StreamingSession = DeepOmit<
  StreamingSessions_solutions_solution_projects_project_streamingSessions,
  "__typename"
>;

export interface SessionProps {
  canExpireSession: boolean;
  sessions?: StreamingSession[];
  expireSession: (userName: string) => void;
  loading: boolean;
}

export default function Sessions({
  canExpireSession,
  expireSession,
  sessions,
  loading
}: SessionProps) {
  const classNames = useStyles({});
  const [openExpireSessionDialog, setOpenExpireSessionDialog] =
    React.useState(false);
  const [userName, setUserName] = React.useState("");
  return sessions ? (
    <div className={classNames.sessions}>
      <Typography variant="h5" gutterBottom>
        Members of this project currently working:
      </Typography>
      <DataGridView<StreamingSession>
        dataSource={sessions || []}
        isLoading={loading}
        columns={[
          {
            colDef: {
              dataField: "isConnected",
              caption: "Is Connected",
              width: 120
            },
            render: ({ data: { isConnected } }) =>
              isConnected ? (
                <Tooltip title="User is connected">
                  <FiberManualRecordIcon
                    className={classNames.icon}
                    color="primary"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="User is not connected, but the session is still running">
                  <RadioButtonUncheckedIcon className={classNames.icon} />
                </Tooltip>
              )
          },
          {
            colDef: {
              dataField: "user.name",
              caption: "Name"
            },
            render: ({ data: { user } }) =>
              user !== null ? <UserName data={user} /> : null
          },
          {
            colDef: {
              dataField: "startTime",
              caption: "Start Time"
            },
            render: p => DateCellRenderer({ value: p.data.startTime })
          },
          {
            colDef: {
              caption: "Actions",
              width: 100,
              allowSorting: false
            },
            render: ({ data }) =>
              data.user?.name ? (
                <SessionActions
                  userName={data.user?.name}
                  canExpireSession={canExpireSession}
                  handleExpireSession={(userName: string) => {
                    setUserName(userName);
                    setOpenExpireSessionDialog(true);
                  }}
                />
              ) : null
          }
        ]}
      />

      <ConfirmGridActionDialog
        message={`You are about to expire the session of ${userName}`}
        onConfirm={() => expireSession(userName)}
        open={openExpireSessionDialog}
        onClose={() => setOpenExpireSessionDialog(false)}
      />
    </div>
  ) : null;
}
