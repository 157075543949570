import { gql, useMutation, useQuery } from "@apollo/client";
import { AddCircle } from "@mui/icons-material";
import { Button, LinearProgress, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import LinkIconButton from "components/base/LinkIconButton";
import { ErrorBox } from "components/error";
import { UserNameCellRenderer } from "components/grid/CellRenderers";
import DataGridView from "components/grid/DataGridView";
import { ActionBarContent } from "features/shell";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import {
  ArchiveSolution,
  ArchiveSolutionVariables
} from "./schema/ArchiveSolution";
import { QueryAllSolutions } from "./schema/QueryAllSolutions";
import {
  RestoreSolution,
  RestoreSolutionVariables
} from "./schema/RestoreSolution";

const solutionsQuery = gql`
  query QueryAllSolutions {
    solutions {
      allSolutions {
        archived
        name
        key
        features {
          projects
          useCases
        }
        description
        creator {
          firstName
          lastName
          name
        }
        creationTime
      }
    }
  }
`;

const archiveSolutionMutation = gql`
  mutation ArchiveSolution($key: Key!) {
    solutions {
      archiveSolution(solutionKey: $key) {
        name
      }
    }
  }
`;

const restoreSolutionMutation = gql`
  mutation RestoreSolution($key: Key!) {
    solutions {
      restoreArchivedSolution(solutionKey: $key) {
        name
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1)
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: 0,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  highlight: {
    backgroundColor: `${grey[200]}`,
    color: "#999",
    "& .dx-checkbox-icon": {
      color: "#999"
    }
  },
  selectArchived: {
    ".dx-datagrid-rowsview &.dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-removed) > td ":
      {
        backgroundColor: `${grey[400]}`,
        color: "#827e7e"
      }
  }
}));

export default function SolutionOverview() {
  const history = useHistory();
  const { loading, data, error } = useQuery<QueryAllSolutions>(solutionsQuery);
  const solutions = data?.solutions.allSolutions || [];
  const [filterValues, setFilterValues] = useState<boolean[]>([]);
  useEffect(() => {
    setFilterValues([false]);
  }, []);
  const [selectedKey, setSelectedKey] = useState<string | undefined>();
  const selectedRow = solutions.find(({ key }) => key === selectedKey);
  const [archiveSolution] = useMutation<
    ArchiveSolution,
    ArchiveSolutionVariables
  >(archiveSolutionMutation);
  const [restoreSolution] = useMutation<
    RestoreSolution,
    RestoreSolutionVariables
  >(restoreSolutionMutation);
  const classes = useStyles();

  if (error) {
    return (
      <ErrorBox
        title="An error occurred while loading the solutions"
        apolloError={error}
      />
    );
  }
  return (
    <>
      <ActionBarContent>
        <LinkIconButton
          to="/create-solution"
          title="Add solution"
          testId="add-solution"
          icon={AddCircle}
        />
      </ActionBarContent>

      <DataGridView
        keyExpr="key"
        dataSource={solutions || []}
        columns={[
          { colDef: { dataField: "name", caption: "Name" } },
          { colDef: { dataField: "key", caption: "Key" } },
          { colDef: { dataField: "description", caption: "Description" } },
          {
            colDef: {
              dataField: "creator.name",
              caption: "Creator",
              minWidth: 300
            },
            render: p => {
              const creator = p.data.creator;
              return UserNameCellRenderer({ user: creator });
            }
          },
          {
            colDef: {
              dataField: "creationTime",
              caption: "Creation Time",
              dataType: "datetime"
            }
          },
          {
            colDef: {
              caption: "Features"
            },
            children: [
              {
                colDef: {
                  dataField: "features.projects",
                  caption: "Project",
                  dataType: "boolean"
                }
              },
              {
                colDef: {
                  dataField: "features.useCases",
                  caption: "Web Service",
                  dataType: "boolean"
                }
              },
              {
                colDef: {
                  dataField: "archived",
                  caption: "Archived",
                  filterValues
                }
              }
            ]
          }
        ]}
        onCellDblClick={param => {
          const archivedSolution = param.data?.archived;
          if (!archivedSolution)
            history.push(createRoute(`/solutions/${param.data.key}`));
        }}
        onRowPrepared={param => {
          if (param.rowType === "data") {
            const { classList } = param.rowElement;
            if (param.data?.archived) classList.add(classes.highlight);
            if (selectedRow?.key === param.data?.key) {
              if (selectedRow.archived) classList.add(classes.selectArchived);
              else classList.remove(classes.selectArchived);
            }
          }
        }}
        onSelectionChanged={param => {
          setSelectedKey(param.selectedRowKeys[0]);
        }}
        onOptionChanged={param => {
          if (param.fullName && param.fullName.endsWith(".filterValues")) {
            const column = param.component
              .getVisibleColumns()
              .filter(({ dataField }) => dataField === "archived");
            if (column) {
              setFilterValues(param.value);
            }
          }
        }}
        withToolbar
        toolbarItemRender={() => (
          <div className={classes.root}>
            {selectedRow && (
              <>
                <Button
                  data-testid="open-solution-button"
                  size="small"
                  variant="contained"
                  className={classes.button}
                  disabled={selectedRow.archived}
                  onClick={() => {
                    const { key } = selectedRow;
                    history.push(createRoute(`/solutions/${key}`));
                  }}
                >
                  Open solution
                </Button>
                <Button
                  data-testid="archive-solution-button"
                  size="small"
                  variant="contained"
                  className={classes.button}
                  disabled={selectedRow.archived}
                  onClick={() => {
                    const { key } = selectedRow;
                    archiveSolution({
                      variables: { key },
                      refetchQueries: [{ query: solutionsQuery }]
                    });
                  }}
                >
                  Archive solution
                </Button>
                <Button
                  data-testid="restore-solution-button"
                  size="small"
                  variant="contained"
                  className={classes.button}
                  disabled={!selectedRow.archived}
                  onClick={() => {
                    const { key } = selectedRow;
                    restoreSolution({
                      variables: { key },
                      refetchQueries: [{ query: solutionsQuery }]
                    });
                  }}
                >
                  Restore solution
                </Button>
              </>
            )}
          </div>
        )}
      ></DataGridView>
      {loading && <LinearProgress />}
    </>
  );
}
