import { Error, Publish } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import LinkIconButton from "components/base/LinkIconButton";
import ErrorBox from "components/error/ErrorBox";
import {
  RestrictedSolutionRealm,
  SolutionPermission
} from "features/access-management";
import { ActionBarContent } from "features/shell";
import { NoUseCaseFound, UseCaseDataSelectionGrid } from "features/use-case";

import { ApolloError } from "@apollo/client";
import { useSolutionKey } from "features/solution";
import _, { debounce } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createRoute, useSearchQueryParam } from "utils/url";
import UploadedDataToolsPanel from "./UploadedDataToolsPanel";
import {
  useMutationScheduleCalculation,
  useQueryUseCaseData
} from "./calculationHooks";
import { UseCaseData_solutions_solution_useCases_useCase_data as TableSetReference } from "./schema/UseCaseData";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden"
  },
  item: {
    flex: 1,
    overflow: "auto"
  },
  progress: {
    minHeight: 4,
    marginBottom: theme.spacing(1)
  },
  errorRoot: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  errorTitle: {
    color: "#b4015b",
    margin: 0,
    fontSize: 56,
    fontWeight: 900
  },
  error: { color: "#191919", marginTop: theme.spacing(3), fontSize: 24 },
  margin: { marginTop: theme.spacing(3) },
  left: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  right: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  image: { height: 250, width: 250 },
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },

  flex: {
    display: "flex",
    alignItems: "center"
  },

  title: {
    borderBottom: `solid 3px ${theme.palette.primary.main}`,
    display: "inline-block",
    color: "#788791",
    marginBottom: 0
  },
  errorIcon: { marginLeft: theme.spacing(1) },
  dialogContent: {
    overflow: "hidden",
    padding: 0,
    "&:first-child": {
      paddingTop: 0
    }
  },
  customWidth: {
    maxWidth: "none",
    minWidth: 400
  }
}));

type CalculateProps = {
  useCaseKey: string;
  useCaseVersion: string;
};

const Calculate: React.FC<CalculateProps> = ({
  useCaseKey,
  useCaseVersion
}) => {
  const solutionKey = useSolutionKey();
  const classNames = useStyles({});
  const [open, setOpen] = React.useState(false);

  const {
    data: allData,
    error,
    loading: loadingUseCaseData
  } = useQueryUseCaseData({
    solutionKey,
    useCaseKey,
    useCaseVersion,
    skipUntilAddress: null,
    limit: 100
  });
  const [mutation] = useMutationScheduleCalculation();

  const history = useHistory();
  const [useCaseDataID, setUseCaseDataId] = useSearchQueryParam("usecasedata");
  const [selectedRow, setSelectedRow] = useState<TableSetReference>();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const scheduleCalculation = async (selectedRow: TableSetReference) => {
    if (!selectedRow) return;
    if (!selectedRow.detail)
      return console.warn(
        `Selected TableSetReference has no 'detail' information`
      );
    const { bucketName, key } = selectedRow.detail;
    const dataSourceAddress = `s3://${bucketName}/${key}`;
    const result = await mutation({
      variables: { solutionKey, useCaseKey, useCaseVersion, dataSourceAddress }
    });
    const calculationId =
      result.data?.solutions.solution?.useCases?.useCase?.scheduleCalculation;
    if (calculationId) {
      history.push({
        pathname: createRoute(
          `/solutions/${solutionKey}/use-cases/${useCaseKey}/${useCaseVersion}/calculations`
        ),
        search: `?calculationId=${calculationId}`
      });
    }
  };
  const handleClicks = (clickType: string, row: TableSetReference) => {
    if (clickType === "singleClick") {
      const id = row.detail?.key.split("/").slice(-1)[0];
      id && setUseCaseDataId(id);
      setSelectedRow(row);
    } else {
      scheduleCalculation(row);
    }
  };
  const debouncedHandleClick = debounce(handleClicks, 500);
  const orderedData = _.orderBy(
    allData?.solutions.solution?.useCases?.useCase?.data,
    a => a.detail && new Date(a.detail?.lastModified),
    ["desc"]
  );

  const ErrorDialog = ({ error }: { error: ApolloError }) => (
    <div className={classNames.flex}>
      <IconButton
        className={classNames.errorIcon}
        onClick={e => {
          e.preventDefault();
          handleOpen();
        }}
        size="large"
      >
        <Error />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paperScrollPaper: classNames.customWidth }}
      >
        <DialogContent className={classNames.dialogContent}>
          <ErrorBox
            apolloError={error}
            title="Errors by loading the use case data!"
          />
        </DialogContent>
      </Dialog>
    </div>
  );

  let errorContent = null;
  if (error) {
    if (open) {
      errorContent = <ErrorDialog error={error} />;
    } else {
      errorContent = (
        <Tooltip title="Errors have occurred while loading the use case data, click this icon to see them.">
          <div>
            <ErrorDialog error={error} />
          </div>
        </Tooltip>
      );
    }
  }

  if (loadingUseCaseData) return <LinearProgress />;
  if (!allData && !error) return <NoUseCaseFound />;

  return (
    <>
      <ActionBarContent>
        <RestrictedSolutionRealm
          requiredPermissions={[SolutionPermission.ChangeUseCaseData]}
          silent
        >
          <LinkIconButton
            to={`/solutions/${solutionKey}/use-cases/${useCaseKey}/${useCaseVersion}/upload-data`}
            title="Upload new data"
            icon={Publish}
            testId="upload-data"
          />
        </RestrictedSolutionRealm>
      </ActionBarContent>
      <div className={classNames.root}>
        <div className={classNames.flex}>
          <Typography
            className={classNames.title}
          >{`${useCaseKey} data:`}</Typography>
          {errorContent}
        </div>

        <Grid item className={classNames.item}>
          <UseCaseDataSelectionGrid
            dataSource={orderedData}
            onSelectionChanged={param => {
              const row = param.selectedRowsData[0];
              if (row) debouncedHandleClick("singleClick", row);
            }}
            onRowDoubleClicked={e => {
              debouncedHandleClick("doubleClick", e.data);
            }}
            toolbar={true}
            toolbarItemRender={() =>
              selectedRow && (
                <UploadedDataToolsPanel
                  history={history}
                  scheduleCalculation={scheduleCalculation}
                  selectedRow={selectedRow}
                />
              )
            }
            dataId={useCaseDataID}
          />
        </Grid>
      </div>
    </>
  );
};

export default Calculate;
